import moment from "moment";
import { getTranslation } from "orion-components/i18n";
import sortBy from "lodash/sortBy";
import flatMap from "lodash/flatMap";
import difference from "lodash/difference";

export const mapRecords = (
	diff,
	current,
	requested,
	level,
	changeType,
	prevValue,
	integrations,
	applications,
	orgs,
	orgRoles,
	locale
) => {
	switch (level) {
		case "user": {
			const result = mapAccountSettingsPage(diff, current, applications);
			return result;
		}
		case "organization": {
			const result = mapOrganization(
				diff,
				current,
				requested,
				changeType,
				prevValue,
				integrations,
				orgs,
				orgRoles,
				locale
			);
			return result;
		}
		case "ecosystem": {
			const result = mapEcosystem(diff, current, changeType);
			return result;
		}
		default:
			return {};
	}
};

const mapAccountSettingsPage = (diff, current, applications) => {
	const { kind, path, rhs, lhs, item } = diff;
	const includesAppSettings = path.includes("appSettings");
	const isNested = typeof rhs === "object" ? true : false;
	const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";
	const isLocale = path.includes("locale");
	const isDefaultApp = path.includes("defaultApp");
	const option = path.at(-1);
	const locale = {
		en: "English",
		ar: "عربي",
		ar_kw: "عربي - الكويت"
	};
	const defaultValues = {
		color: "#35b7f3",
		point: 1,
		timeFormat: "12-hour",
		locale: "English"
	};

	const getAppName = (appId) => {
		const app = applications?.find((app) => app.appId === appId);

		return app
			? app.overrideName
				? app.orgAppName
				: app.name
			: appId;
	};

	switch (kind) {
		case "N": {
			const titleMapping = includesAppSettings ? "settings" : "profile";
			const field = path.join("_");
			const userName = current.user.name;

			if (isNested) {
				const newRecord =
					rhs &&
					Object.entries(rhs).map(([key, value]) => {
						const ignoreChange = defaultValues[key] === value;
						if (ignoreChange) {
							return {};
						}
						return {
							title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${titleMapping}`)}: ${userName}`,
							field: `${field}_${key}`,
							current: defaultValues[key] ?? null,
							requested:
								typeof value === "boolean"
									? `${getTranslation(
										`mainContent.changeRequestQueue.changeRequest.table.toggle.${value ? "off" : "on"
										}`
									)}`
									: isDefaultApp
										? getAppName(value)
										: value
						};
					});
				return newRecord;
			} else {
				const mapping = {
					title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${titleMapping}`)}: ${userName}`,
					field: field,
					current: isBoolean
						? `${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "on" : "off"}`
						)}`
						: isLocale
							? locale[lhs] ?? defaultValues[option]
							: lhs
								? isDefaultApp
									? getAppName(lhs)
									: lhs
								: defaultValues[option],
					requested: isBoolean
						? `${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "on" : "off"}`
						)}`
						: isLocale
							? locale[rhs]
							: isDefaultApp
								? getAppName(rhs)
								: rhs
				};

				if (field.includes("handle")) {
					mapping["target"] = current.user;
				}
				return mapping;
			}
			break;
		}
		case "E": {
			const title = path.at(1);
			const titleMapping = includesAppSettings ? "settings" : "profile";
			const field = path.join("_");
			const userName = current.user.name;
			const isPassword = path.includes("newPassword");

			const mapping = {
				title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${titleMapping}`)}: ${userName}`,
				field: field,
				current: isBoolean
					? `${getTranslation(
						`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "on" : "off"}`
					)}`
					: isLocale
						? locale[lhs] ?? defaultValues[option]
						: isPassword
							? "***************"
							: lhs
								? isDefaultApp
									? getAppName(lhs)
									: lhs
								: defaultValues[option],
				requested: isBoolean
					? `${getTranslation(
						`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "on" : "off"}`
					)}`
					: isLocale
						? locale[rhs]
						: isPassword
							? "***************"
							: isDefaultApp
								? getAppName(rhs)
								: rhs
			};

			if (field.includes("handle")) {
				mapping["target"] = current.user;
			}
			return mapping;
		}
		default:
			return {};
	}
};

const mapOrganization = (diff, current, requested, changeType, prevValue, integrations, orgs, orgRoles, locale) => {
	switch (changeType) {
		case "updateActiveDirectory":
			return mapActiveDirectory(diff, current);
		case "updateOrganizationProfile":
			return mapOrgProfile(diff, current);
		case "updateUserRoles":
			return mapUserRoles(diff, current, requested, changeType, prevValue, integrations, orgRoles);
		case "updateUsers":
			return mapUsers(diff, current, requested, changeType, prevValue, orgRoles);
		case "updateLabels":
			return mapLabels(diff, current, requested, changeType, prevValue, orgRoles);
		case "updateSharingConnections":
			return mapSharingConnections(diff, current, requested, orgs);
		case "updateFeedSharingPolicies":
			return mapFeedSharingPolicies(diff, current, changeType, integrations, orgs, locale);
		case "updateSecondaryFailover":
			return mapSecondaryFailover(diff, current, orgRoles);
		case "updateEventLookups":
			return mapEventLookups(diff, current, changeType, requested);
		default:
			return {};
	}
};

const mapOrgProfile = (diff, current) => {
	const { kind, path, rhs, lhs, item } = diff;

	switch (kind) {
		case "E": {
			const title = path.at(0);
			const field = path.join("_");
			const orgName = current.org.name;

			const mapping = {
				title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${title}`)}: ${orgName}`,
				field: field,
				current: lhs,
				requested: rhs
			};

			if (field.includes("handle")) {
				mapping["target"] = current.org;
			}
			return mapping;
		}
		default:
			return {};
	}
};

const mapActiveDirectory = (diff, current) => {
	const { kind, path } = diff;
	if (path.includes("users")) {
		return {};
	}
	switch (kind) {
		case "E": {
			return mapExisting(diff, current);
		}
		default:
			return {};
	}
};

const mapUserRoles = (diff, current, requested, changeType, prevValue, ints, orgRoles) => {
	const { kind, path, rhs, lhs, item } = diff;
	const isPermissions = path.includes("permissions");

	if (path.includes("roleIds")) {
		const field = path.at(-1);
		const index = path.at(-2);

		const userName = requested.users[index]?.name ?? "";
		const currentRoleIds =
			current.users[index]?.roleIds
				.map((roleId) => orgRoles.find((role) => role.id === roleId)?.title)
				?.filter(Boolean) ?? [];
		const requestedRoleIds =
			requested.users[index]?.roleIds
				.map(
					(roleId) =>
						orgRoles.find((role) => role.id === roleId)?.title ||
						requested.roles?.find((role) => role.id === roleId)?.title
				)
				?.filter(Boolean) ?? [];

		return {
			title: userName,
			field: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${field}`),
			current: currentRoleIds?.join(", "),
			requested: requestedRoleIds?.join(", ")
		};
	}
	switch (kind) {
		case "A": {
			const newMapping = item;
			if (isPermissions) {
				return mapExisting({ ...newMapping, path: diff.path }, current, ints, null, null, null, requested);
			} else if (newMapping.kind === "N") {
				return path.includes("deletedRoles")
					? mapDeleted(prevValue, newMapping.rhs, changeType)
					: typeof newMapping.rhs === "object"
						? mapNew({ ...newMapping, path: diff.path }, current, ints, null, null, null, requested)
						: mapExisting({ ...newMapping, path: diff.path }, current, ints, null, null, null, requested);
			}
			return;
		}
		case "E": {
			return mapExisting(diff, current, ints, null, null, null, requested);
		}
		default:
			return {};
	}
};

const mapUsers = (diff, current, requested, changeType, prevValue, orgRoles) => {
	const { kind, path, rhs, lhs, item } = diff;

	if (path.includes("roleIds")) {
		const field = path.at(-1);
		const index = path.at(-2);

		const userName = requested.users[index]?.name ?? "";
		const currentRoleIds =
			current.users[index]?.roleIds.map((roleId) => orgRoles.find((role) => role.id === roleId)?.title) ?? [];
		const requestedRoleIds =
			requested.users[index]?.roleIds.map(
				(roleId) =>
					orgRoles.find((role) => role.id === roleId)?.title ||
					requested.roles.find((role) => role.id === roleId)?.title
			) ?? [];

		return {
			title: userName,
			field: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${field}`),
			current: currentRoleIds?.join(", "),
			requested: requestedRoleIds?.join(", ")
		};
	}

	switch (kind) {
		case "A": {
			const newMapping = item;
			if (newMapping.kind === "N") {
				return path.includes("deletedUsers")
					? mapDeleted(prevValue, newMapping.rhs, changeType)
					: mapNew({ ...newMapping, path }, changeType, null, null, null, null, orgRoles);
			} else if (newMapping.kind === "D") {
			}
			return {};
		}
		case "E": {
			return mapExisting(diff, current);
		}
		case "N": {
			if (path.at(-1) === "disabled") {
				// special case for users without a "disabled" property.
				return mapExisting(diff, current);
			}
			return mapNew(diff, changeType, null, null, null, null, orgRoles);
		}
		default:
			return {};
	}
};

const mapLabels = (diff, current, requested, changeType, prevValue, orgRoles) => {
	switch (diff.kind) {
		case "N": {
			return mapNew(diff, changeType, null, null, null, requested);
		}
		case "E": {
			return mapExisting(diff, current, null, null, null, null, requested);
		}
		default:
			return {};
	}
};

const mapSharingConnections = (diff, current, requested, orgs) => {
	const { kind, path, rhs, lhs, item } = diff;

	switch (kind) {
		case "A": {
			const newMapping = item;
			if (newMapping.kind === "N") {
				return mapNew({ ...newMapping, path: diff.path }, current, null, orgs, null, null, null);
			}
			return;
		}
		case "E": {
			return mapExisting(diff, current, requested, orgs);
		}
	}
};

const mapFeedSharingPolicies = (diff, current, requested, integrations, orgs, locale) => {
	const { kind, path, rhs, lhs, item } = diff;
	switch (kind) {
		case "A": {
			const newMapping = item;
			if (newMapping.kind === "N" || newMapping.kind === "D") {
				return mapNew({ ...newMapping, path: diff.path }, current, integrations, orgs, locale);
			} else if (newMapping.kind === "D") {
			}
			return {};
		}
		case "E": {
			return mapExisting(diff, current, integrations, orgs, locale);
		}
		default:
			return {};
	}
};

const mapSecondaryFailover = (diff, current, orgRoles) => {
	const { kind, path, rhs, lhs, item } = diff;
	if (path[0] === "alertTimeout") {
		return {
			title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.updateAlertTimeout"),
			field: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.alertTimeout`),
			current: getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"}`
			),
			requested: getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"}`
			)
		};
	}
	switch (kind) {
		case "N":
		case "E":
		case "D": {
			return mapExisting(diff, current, null, null, null, orgRoles);
		}
		default:
			return {};
	}
};

const mapEventLookups = (diff, current, changeType, requested) => {
	const { kind, path, rhs, lhs, item } = diff;

	switch (kind) {
		case "A": {
			const newMapping = item;
			if (newMapping.kind === "N") {
				return mapNew({ ...newMapping, path: diff.path }, current, null, null, null, requested);
			} else if (newMapping.kind === "D") {
				return mapDeleted(current, newMapping.rhs, changeType);
			}
			return;
		}
		case "E": {
			return mapExisting(diff, current, null, null, null, null, requested);
		}
	}
};

const mapEcosystem = (diff, current, changeType) => {
	const { kind, path, rhs, lhs, item } = diff;

	switch (kind) {
		case "A": {
			const newMapping = item;
			if (newMapping.kind === "N") {
				return mapNew({ ...newMapping, path: path }, changeType);
			} else if (newMapping.kind === "D") {
			}
			return {};
		}
		case "N": {
			return mapNew(diff, changeType);
		}
		case "E": {
			return mapExisting(diff, current);
		}
		default:
			return {};
	}
};

const mapExisting = (diff, current, ints, orgs, locale, orgRoles, requested) => {
	const { kind, path, rhs, lhs, item } = diff;

	const title = path.at(0);
	const index = path.at(-2);
	if (title === "apps") {
		const orgId = path.at(-3);
		const org = current.orgs.find((org) => org.orgId === orgId)?.name || orgId;
		const appName = current.apps[orgId][index].name;
		return {
			title: org,
			field: appName,
			current: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "on" : "off"}`
			)}`,
			requested: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "on" : "off"}`
			)}`
		};
	} else if (title === "orgs") {
		const field = path.at(-1);
		const org = current.orgs[index].name;
		const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";
		return {
			title: org,
			field: field,
			current: isBoolean
				? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "off" : "on"}`)}`
				: lhs,
			requested: isBoolean
				? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "off" : "on"}`)}`
				: rhs
		};
	} else if (title === "users") {
		const field = path.at(-1);
		const userName = current.users[index].name;
		const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";
		const isPassword = path.includes("newPassword");
		const invertValues = field === "disabled"; // Note: The disabled property represents an inverted boolean logic, so its comparison should also reflect the inverted state.

		return {
			title: userName,
			field: field,
			current: isBoolean
				? `${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.toggle.${(invertValues ? !lhs : lhs) ? "on" : "off"
					}`
				)}`
				: isPassword
					? "***************"
					: lhs,
			requested: isBoolean
				? `${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.toggle.${(invertValues ? !rhs : rhs) ? "on" : "off"
					}`
				)}`
				: isPassword
					? "***************"
					: rhs
		};
	} else if (title === "roles") {
		const selectedRole = current.roles[path.at(1)];
		const index = path.at(3);
		const includesApps = path.includes("applications");
		const includesInts = path.includes("integrations");
		const { title } = selectedRole;
		const selectedAppInt = includesApps
			? selectedRole.applications[index]?.appId.split("-app")[0].replace("-", " ")
			: includesInts
				? selectedRole.integrations[index]?.intId
				: "-";
		const isPermissions = path.includes("permissions");

		const field = includesInts ? ints.find((int) => int.intId === selectedAppInt)?.name : selectedAppInt;

		if (isPermissions) {
			const roleIndex = path.at(1);
			return mapUserRolePermissions(current, requested, roleIndex, includesApps, index, title, field);
		}

		return {
			title,
			field: field,
			current: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "on" : "off"}`
			)}`,
			requested: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "on" : "off"}`
			)}`
		};
	} else if (title === "integrations") {
		const propsToIgnore = ["enabled", "endDatePickerError", "startDatePickerError"];
		const title = ints.find((int) => int.intId === path.at(1))?.name || path.at(1);
		const field = orgs[path.at(2)]?.name || path.at(2);
		const option = path.at(-1);
		const includesDate = path.at(-1).includes("Date");
		const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";

		if (propsToIgnore.includes(option)) {
			return {};
		}
		const curVal = includesDate
			? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${moment(lhs)
				.locale(locale)
				.format("MM/DD/YYYY")}`
			: isBoolean
				? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"
					}`
				)}`
				: lhs;
		const reqVal = includesDate
			? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${moment(rhs)
				.locale(locale)
				.format("MM/DD/YYYY")}`
			: isBoolean
				? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"
					}`
				)}`
				: rhs;

		return {
			title,
			field,
			current: curVal,
			requested: reqVal
		};
	} else if (title === "connectionData") {
		let newRecord = {};
		if (rhs && typeof rhs === "object") {
			newRecord = Object.entries(rhs).map(([key, value]) => {
				return {
					title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.updateActiveDirectory"),
					field: key,
					current: null,
					requested: key === "password" ? "***************" : value
				};
			});
		} else {
			const field = path.at(-1);
			const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";
			newRecord = {
				title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.updateActiveDirectory"),
				field,
				current:
					field === "password" && lhs
						? "***************"
						: isBoolean
							? `${getTranslation(
								`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"
								}`
							)}`
							: lhs,
				requested:
					field === "password" && rhs
						? "***************"
						: isBoolean
							? `${getTranslation(
								`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"
								}`
							)}`
							: rhs
			};
		}
		return newRecord;
	} else if (title === "failoverSettings") {
		if (path.at(-1) === "revert" || path.at(-1) === "inFailover") {
			return {};
		}
		const orgRoleId = current.failoverSettings[path.at(1)]?.orgRoleId;
		const orgRole = orgRoles?.find((role) => role.id === orgRoleId)?.title;
		const currentName =
			orgRoles?.find((role) => role.id === lhs)?.title ||
			getTranslation(`mainContent.changeRequestQueue.changeRequest.table.none`);
		const requestedName =
			orgRoles?.find((role) => role.id === rhs)?.title ||
			getTranslation(`mainContent.changeRequestQueue.changeRequest.table.none`);

		return {
			title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.updateFailoverRole`),
			field: orgRole,
			current: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.failoverRoleId`
			)}: ${currentName}`,
			requested: `${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.failoverRoleId`
			)}: ${requestedName}`
		};
	} else if (title === "eventLookups") {
		const title = path.at(1);
		const iconProps = ["color", "icon"];
		const currentVal = current.eventLookups[title][index];
		const requestedVal = requested.eventLookups[title][index];
		const isDeleted = path.includes("deleted");
		const ignoredProps = ["group"];
		const mapping = {};

		if (isDeleted) {
			const name = requested.eventLookups[title][index]?.name ?? "";
			return {
				title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.delete.${title}`),
				field: name,
				current: getTranslation("mainContent.changeRequestQueue.changeRequest.table.nonDeleted"),
				requested: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deleted")
			};
		} else if (iconProps.includes(path.at(-1))) {
			mapping.iconProps = {
				lhs: `${currentVal?.icon}.${currentVal?.color}`,
				rhs: `${requestedVal?.icon}.${requestedVal?.color}`
			};
		} else {
			const isBoolean = typeof lhs === "boolean" || typeof rhs === "boolean";
			if (!ignoredProps.includes(path.at(-1))) {
				mapping[path.at(-1)] = {
					lhs: isBoolean
						? `${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.toggle.${lhs ? "on" : "off"}`
						)}`
						: lhs,
					rhs: isBoolean
						? `${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.toggle.${rhs ? "on" : "off"}`
						)}`
						: rhs
				};
			}
		}

		const newRecord = Object.entries(mapping).map(([key, value]) => {
			return {
				title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.edit.${title}`)}: ${currentVal?.name ?? requestedVal?.name
					}`,
				field: key,
				current: value?.lhs,
				requested: value?.rhs
			};
		});

		return newRecord;
	} else if (title === "establishConnection") {
		const org = orgs[current.orgId]?.name ?? current.orgId;
		const sharingConnections = flatMap(Object.values(orgs).map((org) => org.sharingConnections));
		const requestedValue = sharingConnections.find((conn) => conn.id === rhs);

		return {
			title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.establishConnection"),
			field: org,
			current: null,
			requested: requestedValue.sourceOrgName
		};
	} else if (title === "appLabels") {
		const field = path.at(-1);
		const appName = requested?.appLabels[path.at(1)]?.name;
		const currentVal =
			field === "overrideName"
				? getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"
					}`
				)
				: lhs;
		const requestedVal =
			field === "overrideName"
				? getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"
					}`
				)
				: rhs;

		return {
			title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.applicationLabel`, appName),
			field,
			current: currentVal,
			requested: requestedVal
		};
	} else if (title === "intLabels") {
		const field = path.at(-1);
		const intName = requested?.intLabels[path.at(1)]?.name;
		const currentVal =
			field === "overrideName"
				? getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"
					}`
				)
				: lhs;
		const requestedVal =
			field === "overrideName"
				? getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"
					}`
				)
				: rhs;

		return {
			title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.integrationLabel`, intName),
			field,
			current: currentVal,
			requested: requestedVal
		};
	}
	return {};
};

const mapNew = (diff, changeType, ints, orgs, locale, requested, orgRoles) => {
	const { kind, path, rhs, lhs, item } = diff;
	const title = path.at(0);

	const changes = rhs || {};
	const { orgId, admin, ecoAdmin, role, roleIds, handle, maxSharingConnections, ...update } = changes;

	if (title === "users") {
		const roles = roleIds?.map((roleId) => orgRoles.find((role) => role.id === roleId)?.title) ?? [];
		const mapping = {
			adminFirstName: update.name.split(" ").slice(0, -1).join(" "),
			adminLastName: update.name.split(" ").slice(-1).join(" "),
			adminEmail: update.email,
			adminCell: update.contact.cellPhone,
			adminOffice: update.contact.officePhone,
			roleIds: roles?.join(", ")
		};
		if (handle) {
			mapping["org_handle"] = handle;
		}
		const requestTitle = changeType === "updateUsers"
			? "newUser"
			: changeType === "updateEcosystem"
				? "initialUser"
				: "newOrg";
		const newRecord = Object.entries(mapping).map(([key, value]) => {
			return {
				title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${requestTitle}`, orgId),
				field: key,
				current: null,
				requested: value
			};
		});
		return newRecord;
	} else if (title === "orgs") {
		const mapping = {
			orgName: update.name,
			description: update.description
		};
		if (handle) {
			mapping["org_handle"] = handle;
		}
		if (maxSharingConnections) {
			mapping["maxSharingConnections"] = maxSharingConnections;
		}
		const newRecord = Object.entries(mapping).map(([key, value]) => {
			return {
				title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.newOrg`),
				field: key,
				current: null,
				requested: value
			};
		});
		return newRecord;
	} else if (title === "roles") {
		const { applications, integrations, title } = rhs;
		const mapping = {
			name: title
		};
		const appInt = [];
		if (applications) {
			applications.map((app) => {
				const appName = app.appId.split("-app")[0].replace("-", " ");
				mapping[appName] = `${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.toggle.${app.config.canView ? "on" : "off"}`
				)}`;
				app.permissions?.map((permission) => {
					appInt.push({
						[appName]: `${`${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.${permission}`
						)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)}`
					});
				});
			});
		}
		if (integrations) {
			integrations.map((integration) => {
				const intName = ints.find((int) => int.intId === integration.intId)?.name || integration.intId;
				mapping[intName] = `${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.toggle.${integration.config.canView ? "on" : "off"
					}`
				)}`;
				integration.permissions?.map((permission) => {
					appInt.push({
						[intName]: `${`${getTranslation(
							`mainContent.changeRequestQueue.changeRequest.table.${permission}`
						)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)}`
					});
				});
			});
		}
		if (maxSharingConnections) {
			mapping["maxSharingConnections"] = maxSharingConnections;
		}
		const newRecord = Object.entries(mapping).map(([key, value]) => {
			return {
				title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.newRole`),
				field: key,
				current: null,
				requested: value
			};
		});
		const arr = appInt.map((value) => {
			const [val] = Object.entries(value);
			return {
				title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.newRole`),
				field: val[0],
				current: null,
				requested: val[1]
			};
		});
		return [...newRecord, ...arr];
	} else if (title === "integrations") {
		const propsToIgnore = ["enabled", "endDatePickerError", "startDatePickerError"];
		const title = ints.find((int) => int.intId === path.at(1))?.name || path.at(1);
		const field = orgs[path.at(2)]?.name || path.at(2);
		const option = lhs || rhs;
		const includesDate = path.at(-1).includes("Date");

		if (propsToIgnore.includes(path.at(-1))) {
			return {};
		}

		const curVal = includesDate
			? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${moment(lhs)
				.locale(locale)
				.format("MM-DD-YYYY HH:mm")}`
			: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.${lhs ? "enabledPermission" : "disabledPermission"
				}`
			)}`;
		const reqVal = includesDate
			? `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${moment(rhs)
				.locale(locale)
				.format("MM-DD-YYYY HH:mm")}`
			: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${option}`)}: ${getTranslation(
				`mainContent.changeRequestQueue.changeRequest.table.${rhs ? "enabledPermission" : "disabledPermission"
				}`
			)}`;

		return {
			title,
			field,
			current: curVal,
			requested: reqVal
		};
	} else if (title === "eventLookups") {
		const title = path.at(1);

		const mapping = {
			name: { rhs: rhs.name }
		};

		if (rhs.icon) mapping.iconProps = { rhs: `${rhs?.icon}.${rhs?.color}` };

		const newRecord = Object.entries(mapping).map(([key, value]) => {
			return {
				title: `${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${title}`)}: ${rhs.name}`,
				field: key,
				current: null,
				requested: value.rhs
			};
		});

		return newRecord;
	} else if (title === "newConnections") {
		const sourceOrg = rhs.sourceOrg;
		const field = orgs[sourceOrg]?.name ?? sourceOrg;
		return {
			title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.newConnection"),
			field,
			current: null,
			requested: rhs.id
		};
	} else if (title === "removedConnections") {
		const connType = getTranslation(`mainContent.changeRequestQueue.changeRequest.table.${rhs?.connType}`);
		return {
			title: `${connType}: ${getTranslation("mainContent.changeRequestQueue.changeRequest.table.removedConnections")}`,
			field: rhs?.orgName,
			current: getTranslation("mainContent.changeRequestQueue.changeRequest.table.nonDeleted"),
			requested: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deleted")
		};
	} else if (title === "appLabels") {
		const field = path.at(-1);
		const appName = requested.appLabels[path.at(1)]?.name;
		const currentVal =
			field === "overrideName"
				? getTranslation(`mainContent.changeRequestQueue.changeRequest.table.disabledPermission`)
				: appName;
		const requestedVal =
			field === "overrideName"
				? getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)
				: rhs;

		return {
			title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.applicationLabel`, appName),
			field,
			current: currentVal,
			requested: requestedVal
		};
	} else if (title === "intLabels") {
		const field = path.at(-1);
		const intName = requested.intLabels[path.at(1)]?.name;
		const currentVal =
			field === "overrideName"
				? getTranslation(`mainContent.changeRequestQueue.changeRequest.table.disabledPermission`)
				: intName;
		const requestedVal =
			field === "overrideName"
				? getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)
				: rhs;

		return {
			title: getTranslation(`mainContent.changeRequestQueue.changeRequest.table.integrationLabel`, intName),
			field,
			current: currentVal,
			requested: requestedVal
		};
	}
	return {};
};

const mapDeleted = (current, deletedId, changeType) => {
	if (changeType === "updateUsers") {
		const deletedUser = current.users.find((item) => item.id === deletedId);

		return {
			title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deletedUsers"),
			field: deletedUser.name,
			current: getTranslation("mainContent.changeRequestQueue.changeRequest.table.nonDeleted"),
			requested: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deleted")
		};
	} else if (changeType === "updateUserRoles") {
		const deletedRole = current.roles.find((item) => item.id === deletedId);

		return {
			title: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deletedRoles"),
			field: deletedRole.title,
			current: getTranslation("mainContent.changeRequestQueue.changeRequest.table.nonDeleted"),
			requested: getTranslation("mainContent.changeRequestQueue.changeRequest.table.deleted")
		};
	}
};

export const filterDeletedRecords = (objectA, objectB, changeType) => {
	const includesDeleted = ["updateUsers", "updateUserRoles"].includes(changeType);
	const current = { ...objectA };
	const requested = { ...objectB };

	if (includesDeleted) {
		if (changeType === "updateUsers") {
			const deletedUsers = requested?.deletedUsers;
			const currentUpdate = objectA?.users.filter((item) => !deletedUsers.includes(item.id));
			const requestedUpdate = objectB?.users.filter((item) => !deletedUsers.includes(item.id));
			current["users"] = currentUpdate;
			requested["users"] = requestedUpdate;
		} else if (changeType === "updateUserRoles") {
			const deletedRoles = requested?.deletedRoles;
			const currentUpdate = objectA?.roles.filter((item) => !deletedRoles.includes(item.id));
			const requestedUpdate = objectB?.roles.filter((item) => !deletedRoles.includes(item.id));
			current["roles"] = sortBy(currentUpdate, "title").reverse();
			requested["roles"] = sortBy(requestedUpdate, "title").reverse();
		}
	}
	return { current, requested };
};

const mapUserRolePermissions = (current, requested, roleIndex, includesApps, index, title, field) => {
	const currentRole = current.roles[roleIndex];
	const requestedRole = requested.roles[roleIndex];
	const extract = includesApps ? "applications" : "integrations";
	const addedPermissions = difference(
		requestedRole[extract][index].permissions,
		currentRole[extract][index].permissions
	);
	const removedPermissions = difference(
		currentRole[extract][index].permissions,
		requestedRole[extract][index].permissions
	);
	let newRecord = [];

	if (addedPermissions.length > 0) {
		addedPermissions.map((item) => {
			newRecord.push({
				title,
				field,
				current: `${`${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${item}`
				)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.disabledPermission`)}`,
				requested: `${`${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${item}`
				)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)}`
			});
		});
	}
	if (removedPermissions.length > 0) {
		removedPermissions.map((item) => {
			newRecord.push({
				title,
				field,
				current: `${`${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${item}`
				)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.enabledPermission`)}`,
				requested: `${`${getTranslation(
					`mainContent.changeRequestQueue.changeRequest.table.${item}`
				)}`}: ${getTranslation(`mainContent.changeRequestQueue.changeRequest.table.disabledPermission`)}`
			});
		});
	}
	return newRecord;
};
